
if(process.client){
    window.popStateDetected = false
    window.addEventListener('popstate', () => {
      window.popStateDetected = true
    })
}

export default defineNuxtRouteMiddleware((to, from, add) => {
    if (to.path !== from.path && process.client) {
        let history = useState('app-history', () => [])

        if(window.popStateDetected) {
            history.value.pop()
        }

        //console.log(to, from, add, window.popStateDetected)

        if(to.name == from.name && to.params?.id == from.params?.id && to.params?.slug != from.params?.slug && to.name != 'product-slug') return
        window.scrollTo(0, 0)
        
        if((to.name != from.name || to.params?.id != from.params?.id || to.params?.slug?.length != from.params?.slug?.length) && !window.popStateDetected) {
            if( history.value[history.value.length-1]?.path == from.path)
                history.value.pop()
            else
                history.value.push(from)
        }
        window.popStateDetected = false
    }
  })