import { default as _91_46_46_46slug_93CRFxdqMiCdMeta } from "/var/www/cert/certification-platform-frontend/pages/[...slug].vue?macro=true";
import { default as AppYk827UZu6mMeta } from "/var/www/cert/certification-platform-frontend/pages/App.vue?macro=true";
import { default as _91_46_46_46slug_936DLkozUmk0Meta } from "/var/www/cert/certification-platform-frontend/pages/business/[id]/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93TcDs5l1mwOMeta } from "/var/www/cert/certification-platform-frontend/pages/businesses/[...slug].vue?macro=true";
import { default as _91id_93QVS3DWkSJlMeta } from "/var/www/cert/certification-platform-frontend/pages/certification/[id].vue?macro=true";
import { default as _91_46_46_46slug_93Zq7j2RHMUuMeta } from "/var/www/cert/certification-platform-frontend/pages/certification/check/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93e9wkq0dD2FMeta } from "/var/www/cert/certification-platform-frontend/pages/certification/create/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_936wS3IAztCMMeta } from "/var/www/cert/certification-platform-frontend/pages/certifications/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93yceSxDny3yMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/action-message/[...slug].vue?macro=true";
import { default as index8h2AatnjXSMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/action-messages/index.vue?macro=true";
import { default as indexx9UYFhmLCGMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/categories/index.vue?macro=true";
import { default as _91_46_46_46slug_93UNrVjCTNaBMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/category/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93BNUXnbUvt4Meta } from "/var/www/cert/certification-platform-frontend/pages/configuration/help-text/[...slug].vue?macro=true";
import { default as indexGV4QM4paUEMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/help-texts/index.vue?macro=true";
import { default as indexpdLGBQzuzuMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/import/index.vue?macro=true";
import { default as _91_46_46_46slug_93myhf8ATSTxMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/marketing-question/[...slug].vue?macro=true";
import { default as indexwgZ1jVSZKEMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/marketing-questions/index.vue?macro=true";
import { default as indexZ2SHdax7WHMeta } from "/var/www/cert/certification-platform-frontend/pages/configuration/templates/index.vue?macro=true";
import { default as Confirm_45EmailD2ByRABjpTMeta } from "/var/www/cert/certification-platform-frontend/pages/Confirm-Email.vue?macro=true";
import { default as indexrAKpRRakmnMeta } from "/var/www/cert/certification-platform-frontend/pages/export/index.vue?macro=true";
import { default as indexZOeOX3qjGPMeta } from "/var/www/cert/certification-platform-frontend/pages/help/index.vue?macro=true";
import { default as Imprint2gvU4AJK9sMeta } from "/var/www/cert/certification-platform-frontend/pages/Imprint.vue?macro=true";
import { default as index4mqebU0tQwMeta } from "/var/www/cert/certification-platform-frontend/pages/index.vue?macro=true";
import { default as LoginBtmFOzB8okMeta } from "/var/www/cert/certification-platform-frontend/pages/Login.vue?macro=true";
import { default as MaintenanceAkR0RTc91qMeta } from "/var/www/cert/certification-platform-frontend/pages/Maintenance.vue?macro=true";
import { default as _91_46_46_46slug_93mEuvVylPy1Meta } from "/var/www/cert/certification-platform-frontend/pages/organization/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93h7AHhsQetnMeta } from "/var/www/cert/certification-platform-frontend/pages/organizations/[...slug].vue?macro=true";
import { default as PrivacyrUaAm6Ia2YMeta } from "/var/www/cert/certification-platform-frontend/pages/Privacy.vue?macro=true";
import { default as _91_46_46_46slug_93OdvqbW9UkWMeta } from "/var/www/cert/certification-platform-frontend/pages/product/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93xispo1QECTMeta } from "/var/www/cert/certification-platform-frontend/pages/products/[...slug].vue?macro=true";
import { default as Reset_45Password7Rxmpu1WXhMeta } from "/var/www/cert/certification-platform-frontend/pages/Reset-Password.vue?macro=true";
import { default as Terms3tFFWBjWdcMeta } from "/var/www/cert/certification-platform-frontend/pages/Terms.vue?macro=true";
import { default as _91_46_46_46slug_93OxRVSuwjRPMeta } from "/var/www/cert/certification-platform-frontend/pages/user/[id]/[...slug].vue?macro=true";
import { default as DeletemMOlpCiZAbMeta } from "/var/www/cert/certification-platform-frontend/pages/user/[id]/Delete.vue?macro=true";
import { default as Add45qHZNq0tfMeta } from "/var/www/cert/certification-platform-frontend/pages/user/Add.vue?macro=true";
import { default as index5jawny02rWMeta } from "/var/www/cert/certification-platform-frontend/pages/users/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/[...slug].vue")
  },
  {
    name: "App",
    path: "/App",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/App.vue")
  },
  {
    name: "business-id-slug",
    path: "/business/:id()/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/business/[id]/[...slug].vue")
  },
  {
    name: "businesses-slug",
    path: "/businesses/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/businesses/[...slug].vue")
  },
  {
    name: "certification-id",
    path: "/certification/:id()",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/certification/[id].vue")
  },
  {
    name: "certification-check-slug",
    path: "/certification/check/:slug(.*)*",
    meta: _91_46_46_46slug_93Zq7j2RHMUuMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/certification/check/[...slug].vue")
  },
  {
    name: "certification-create-slug",
    path: "/certification/create/:slug(.*)*",
    meta: _91_46_46_46slug_93e9wkq0dD2FMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/certification/create/[...slug].vue")
  },
  {
    name: "certifications-slug",
    path: "/certifications/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/certifications/[...slug].vue")
  },
  {
    name: "configuration-action-message-slug",
    path: "/configuration/action-message/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/action-message/[...slug].vue")
  },
  {
    name: "configuration-action-messages",
    path: "/configuration/action-messages",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/action-messages/index.vue")
  },
  {
    name: "configuration-categories",
    path: "/configuration/categories",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/categories/index.vue")
  },
  {
    name: "configuration-category-slug",
    path: "/configuration/category/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/category/[...slug].vue")
  },
  {
    name: "configuration-help-text-slug",
    path: "/configuration/help-text/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/help-text/[...slug].vue")
  },
  {
    name: "configuration-help-texts",
    path: "/configuration/help-texts",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/help-texts/index.vue")
  },
  {
    name: "configuration-import",
    path: "/configuration/import",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/import/index.vue")
  },
  {
    name: "configuration-marketing-question-slug",
    path: "/configuration/marketing-question/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/marketing-question/[...slug].vue")
  },
  {
    name: "configuration-marketing-questions",
    path: "/configuration/marketing-questions",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/marketing-questions/index.vue")
  },
  {
    name: "configuration-templates",
    path: "/configuration/templates",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/configuration/templates/index.vue")
  },
  {
    name: "Confirm-Email",
    path: "/Confirm-Email",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Confirm-Email.vue")
  },
  {
    name: "export",
    path: "/export",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/export/index.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/help/index.vue")
  },
  {
    name: "Imprint",
    path: "/Imprint",
    meta: Imprint2gvU4AJK9sMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Imprint.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index4mqebU0tQwMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/index.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: LoginBtmFOzB8okMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Login.vue")
  },
  {
    name: "Maintenance",
    path: "/Maintenance",
    meta: MaintenanceAkR0RTc91qMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Maintenance.vue")
  },
  {
    name: "organization-slug",
    path: "/organization/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/organization/[...slug].vue")
  },
  {
    name: "organizations-slug",
    path: "/organizations/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/organizations/[...slug].vue")
  },
  {
    name: "Privacy",
    path: "/Privacy",
    meta: PrivacyrUaAm6Ia2YMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Privacy.vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/product/[...slug].vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/products/[...slug].vue")
  },
  {
    name: "Reset-Password",
    path: "/Reset-Password",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Reset-Password.vue")
  },
  {
    name: "Terms",
    path: "/Terms",
    meta: Terms3tFFWBjWdcMeta || {},
    component: () => import("/var/www/cert/certification-platform-frontend/pages/Terms.vue")
  },
  {
    name: "user-id-slug",
    path: "/user/:id()/:slug(.*)*",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/user/[id]/[...slug].vue")
  },
  {
    name: "user-id-Delete",
    path: "/user/:id()/Delete",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/user/[id]/Delete.vue")
  },
  {
    name: "user-Add",
    path: "/user/Add",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/user/Add.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/cert/certification-platform-frontend/pages/users/index.vue")
  }
]